var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    { attrs: { title: "无痕刷新示例", bordered: false } },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "8px" } },
        [
          _c("span", [_vm._v("启用数据变动特效：")]),
          _c("a-switch", {
            model: {
              value: _vm.reloadEffect,
              callback: function ($$v) {
                _vm.reloadEffect = $$v
              },
              expression: "reloadEffect",
            },
          }),
        ],
        1
      ),
      _c("j-vxe-table", {
        ref: "table",
        attrs: {
          "row-number": "",
          "row-selection": "",
          "keep-source": "",
          "socket-reload": "",
          "socket-key": "demo-socket-reload",
          "reload-effect": _vm.reloadEffect,
          height: 340,
          loading: _vm.loading,
          columns: _vm.columns,
          dataSource: _vm.dataSource,
        },
        on: { "edit-closed": _vm.handleEditClosed },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }